<template>
  <page-layout title="About" linkDefault1="/" :backButton="true">
    <div class=" h-screen place-items-center mt-12">
      <div class="flex flex-col justify-center items-center space-y-8">
        <img class="w-72" :src="require('@/assets/logo-transparent.png')" alt="" />
        <div class="max-w-lg px-4 space-y-4">
          <p class=" font-poppins text-sm text-center font-normal whitespace-pre-line" style="white-space: pre-line !important">
            Tutoruu is a social network that connects undergraduate students with their university community to get
            access to a feed full of questions, class notes, reviews, tips and tricks, and even course-specific peer
            tutoring with high-achieving peers who have aced that course in the past. <br>
            Developed by a small team of once-struggling undergraduate students, our mission is to ensure that students
            are academically, mentally and financially empowered throughout their university experience.
            You can find the full list of universities we’re operating in here
            <br>
            <br>
            <strong>Our Philosophy</strong>
            <br>
            Universities can be pressuring environments, and studying can often become anxiety-inducing. Tutoruu strives
            to redefine how we perceive success; deconstructing asking for help, talking about money, and sharing our
            individual struggles sits at the core of our company. <br>
            We have 9 core values that we revolve our company around: <br>
            Community: A community empowers individuals <br>
            Leadership: The courage to shape a better future for students <br>
            Student-oriented: Struggling Students come first <br>
            Passion: A workplace fueled by passion <br>
            Accountability: Have accountability for your own work <br>
            Feedback-oriented: Strive for feedback in every step of the way <br>
            Communication: Team communication is key to our success <br>
            Learn: Know that you don’t know <br>
            Iterate: Listen, learn and iterate <br>
            <br>
            <strong>Our Story</strong>
            <br>
            Seif Amr, CEO, and Youssef Abdelhamid, CPO, founded Tutoruu after sharing with one another how intensely
            they were struggling in their courses, but had no effective, convenient, or accessible options to get help.
            In looking for support, they turned to professors, teaching assistants, and even tutoring centers, but these
            avenues failed to offer a full understanding of students struggles. <br>
            When Youssef and Seif started working on Tutoruu, they wanted to normalize the concept of asking for help.
            From the other hand, they wanted to normalize students getting paid to help one another.
            In realizing that creating communities of support is the most effective way to thrive, we gave birth to
            Tutoruu. The tremendous change that we have seen coming out of students helping one another was far beyond
            what we have expected, and we hold immense pride in that. <br>
            ❤️ كله بالحب
            <br>
            The Tutoruu Family
          </p>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import pageLayout from "../../components/base/pageLayout.vue";
export default {
  components: { pageLayout },
};
</script>

